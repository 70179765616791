import { useStore } from "@nanostores/react";
import { destinationsStore, ciApiAvailableStore } from "@root/framework/stores/app-store";
import { userRootDataStore } from "@root/framework/stores/user-store";
import { useEffect } from "react"

const DestinationsLoader = () => {

    const $intercomApiAvailable = useStore(ciApiAvailableStore);
    const $userRootData = useStore(userRootDataStore);

    useEffect(() => {
        if (!$intercomApiAvailable) return;

        if ($userRootData.globalAreasIds?.length <= 0) {
            console.error("USUARIO NO TIENE NINGÚN AREA ASIGNADA");
            return;
        }

        fetch(`${import.meta.env.PUBLIC_INTERCOM_API_URL}/v2/areas/${$userRootData.globalAreasIds[0]}/data/apps/intercom/callbook`)
            .then(res => res.json())
            .catch(err => console.log(err))
            .then((data) => {
                destinationsStore.set(data)
            })
            .catch(err => console.log(err))

    }, [$intercomApiAvailable]);
}

export default DestinationsLoader